<template>
  <div>
    <CommonStandardList
        slug="Advance Licensee"
        :module-name="STORE_MODULE_NAME"
        :table-headers="tableHeaders"
        :form-setting="form"
        :api-urls="apiUrls"
    >
      <template #[`item.actions`]="{ item }">
        <v-btn
            color="primary"
        >
          Disburse
        </v-btn>
      </template>
    </CommonStandardList>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {onUnmounted, ref} from "@vue/composition-api"

// sidebar
import CommonStandardList from "@/views/common/list/standard-list/CommonStandardList"
import store from "@/store"
import companyStoreModule from "@/views/company-management/companies/companyStoreModule"
import useCompaniesList from "@/views/company-management/companies/company-list/useCompaniesList"
import useCompanyConfigList from "@/views/company-management/companies/company-config/useCompanyConfigList"

export default {
  components: {
    CommonStandardList
  },
  mounted() {
  },
  setup() {
    const STORE_MODULE_NAME = "arm-licensee-advance"

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, companyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const apiUrls = ref(
        {
          list: "/admin/licensee-advance",
          add: "/admin/licensee-advance"
        }
    )

    const employmentType = ref([
      "PERMANENT",
      "PROBATION",
      "THIRD-PARTY"
    ])

    const tableHeaders = ref([
      {text: "Company Name", value: "company_id", filter: true},
      {text: "Employee Name", value: "employee_id"},
      {text: "Amount", value: "amount"},
      {text: "Status", value: "status"},
      {text: "Created At", value: "created_at", sortable: false},
      {
        text: "ACTIONS",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ])

    const form = ref([
    ])

    const searches = []

    return {
      STORE_MODULE_NAME,
      tableHeaders,
      form,
      apiUrls,
      // icons
      icons: {},
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
